















































import { Vue, Component, Ref, Prop } from 'vue-property-decorator'
import FormDrawer from '@/components/common/FormDrawer/index.vue'
import { phoneNumber, validate, identityNumber } from '@/utils/helpers'
import { ElForm } from 'element-ui/types/form'

@Component({ components: { FormDrawer } })
export default class VisitorAdder extends Vue {
  @Prop({ default: false }) visible: boolean

  @Ref() form: ElForm

  formData: AnyObj = { name: '', identityNumber: '', tel: '', business: '', expireAt: 0 }
  rules = {
    name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
    identityNumber: [
      { required: true, message: '请输入身份证号', trigger: 'blur' },
      {
        validator: (rule: any, value: string, callback: Function) => {
          if (!validate.identityNumber(value)) callback(new Error('请输入正确的身份证号'))
          else callback()
        },
        trigger: 'blur'
      }
    ],
    tel: [
      { required: true, message: '请输入电话号码', trigger: 'blur' },
      {
        validator: (rule: any, value: string, callback: Function) => {
          if (!validate.phoneNumber(value)) callback(new Error('请输入正确的电话号码'))
          else callback()
        },
        trigger: 'blur'
      }
    ],
    business: [{ required: true, message: '请输入拜访事由', trigger: 'blur' }],
    expireAt: [{ required: true, message: '请选择有效天数', trigger: 'blur' }]
  }

  loading = false

  show() {
    this.visible = true
  }

  async submit() {
    this.form.validate(async valid => {
      if (valid) {
        const { tel, expireAt } = this.formData
        const formData = {
          ...this.formData,
          tel: phoneNumber.standardize(tel),
          expireAt: this.$moment().add(expireAt, 'day'),
          gender: identityNumber.getGender(this.formData.identityNumber)
        }

        const { data } = await this.$api.europa.createVisitor(formData)

        if (data.code === 0) {
          this.$message({ message: '新建成功', type: 'success' })
          this.$emit('added')
          this.visible = false
        }
      }
    })
  }

  close() {
    this.form.resetFields()
    this.formData = {}
  }
}
