




















































































































































import { Vue, Component, Ref } from 'vue-property-decorator'
import PageTitle from '@/components/common/pageTitle.vue'
import DatePicker from '@/components/common/DatePicker.vue'
import InputSearch from '@/components/common/InputSearch.vue'
import SecurityText from '@/components/common/SecurityText/index.vue'
import Modal from '@/components/common/Modal'
import VisitorAdder from './VisitorAdder.vue'
import { visitor, options } from '@/utils/options'
import { record, is, phoneNumber, validate } from '@/utils/helpers'
import { ElTable } from 'element-ui/types/table'

type SearchType = 'name' | 'identityNumber' | 'tel'

const drawer = Modal(VisitorAdder)

@Component({ components: { PageTitle, DatePicker, InputSearch, SecurityText, VisitorAdder } })
export default class VisitorRecords extends Vue {
  @Ref() table: ElTable

  filterData: AnyObj = {
    startTime: this.$moment().startOf('day').toISOString(),
    endTime: this.$moment().endOf('day').toISOString(),
    authType: '',
    status: ''
  }
  pagination = { pageSize: 20, currentPage: 1 }
  searchType: 'name' | 'identityNumber' | 'tel' = 'name'
  searchRules: { [K in SearchType]?: (v: string) => string | void } = {
    identityNumber: v => {
      if (!validate.identityNumber(v)) return '请输入正确的身份证号'
    }
  }
  visitorList: AnyObj[] = []
  visitorTotal = 0
  loading = false

  get placeholder() {
    return this.searchType === 'name' ? '请输入访客姓名查询' : '请输入证件号查询'
  }

  get statusOptions() {
    return visitor.statuses
  }

  get typeOptions() {
    return visitor.types
  }

  get searchTypeOptions() {
    return visitor.searchTypes
  }
  get params() {
    const params = record(this.filterData).filter(is.ava)
    const { status, tel } = params
    const { currentPage, pageSize } = this.pagination

    if (is.num(status)) {
      params.status = [status]
    } else {
      params.status = visitor.statuses.map(option => option.value)
    }
    if (is.str(tel)) params.tel = phoneNumber.standardize(tel)

    return { offset: (currentPage - 1) * pageSize, limit: pageSize, ...params }
  }

  created() {
    this.fetchVisitorList()

    drawer.on('added', () => this.fetchVisitorList())
  }

  getStatus(value: string) {
    return options(this.statusOptions).find(value) ?? {}
  }

  getType(value: string) {
    return options(this.typeOptions).find(value) ?? {}
  }

  setParams(data: AnyObj) {
    this.filterData = { ...this.filterData, ...data }
  }

  filter() {
    this.pagination.currentPage = 1
    this.fetchVisitorList()
  }

  async fetchVisitorList() {
    this.loading = true
    const { data } = await this.$api.europa.getVisitors(this.params)
    this.loading = false

    if (data.code === 0) {
      this.visitorList = data.data
      this.visitorTotal = data.count
    }
  }

  dateChange([start, end]: [Date, Date]) {
    this.setParams({ startTime: start.toISOString(), endTime: end.toISOString() })
  }

  async pageChange() {
    await this.fetchVisitorList()

    this.table.bodyWrapper.scrollTop = 0
  }

  selectSearchType(type: any) {
    this.searchTypeOptions.forEach(({ value }) => {
      delete this.filterData[value]
    })

    this.searchType = type
  }

  searchInputChange(value: any) {
    if (value) {
      const validate = this.searchRules[this.searchType]
      const message = validate && validate(value)

      if (message) {
        return this.$message({ message, type: 'error' })
      }

      if (this.searchType === 'tel') value = phoneNumber.standardize(value)

      const fuzzyFields = ['name']

      this.setParams({ [this.searchType]: value, fuzzy: fuzzyFields.includes(this.searchType) })
    } else {
      this.setParams({ [this.searchType]: null, fuzzy: null })
    }

    this.filter()
  }

  addVisitor() {
    drawer.open()
  }
}
